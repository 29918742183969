'use client'

import type { JSX } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { SubcategoryWithArticleIds } from '@/contentful/articles'
import type {
  ArticleSchema,
  SubCategorySchema,
} from '@/contentful/contentful-schema-types'

import { PAGE_SIZE } from '@/lib/constants'
import { identity, uniqBy } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { ArticleCard } from '@/components/article-card'
import { getArticles } from '@/app/(marketing)/(auth-nav)/categories/[categoryId]/actions'

import { AutoComplete } from './autocomplete'
import { Label } from './ui/label'

interface SubcategoriesFilterProps {
  subcategories: SubCategorySchema[]
  setCurrentSubcategory: (subcategory?: SubCategorySchema) => void
  currentSubcategory?: SubCategorySchema
  disabled?: boolean
}

const getSlug = (item: SubCategorySchema) => {
  return item.fields.slug
}

function SubcategoriesFilter({
  subcategories,
  setCurrentSubcategory,
  currentSubcategory,
  disabled = false,
}: SubcategoriesFilterProps) {
  const options = useMemo(() => {
    return subcategories.map((subcategory) => ({
      value: subcategory.sys.id,
      label: subcategory.fields.title,
    }))
  }, [subcategories])

  return (
    <div className={'flex flex-col gap-y-2 pb-7 lg:pb-9'}>
      <Label htmlFor="article-search">Filter by topic</Label>
      <AutoComplete
        options={options}
        emptyMessage="No results found."
        onValueChange={({ value }) => {
          setCurrentSubcategory(
            subcategories.find((sub) => sub.sys.id === value)
          )
        }}
        onClear={() => setCurrentSubcategory(undefined)}
        value={options.find(
          ({ value }) => currentSubcategory?.sys.id === value
        )}
        disabled={disabled}
        id="article-search"
      />
    </div>
  )
}

interface ArticlesWithFilterProps extends Omit<ArticlesProps, 'children'> {
  searchOptions: SubcategoryWithArticleIds[]
  title?: string
}

interface ArticlesProps {
  subcategories?: SubCategorySchema[]
  articlesToIgnore?: ArticleSchema[]
  articleIds?: string[]
  initialArticles: ArticleSchema[]
  initialTotal: number
  children?: JSX.Element
}

export function ArticlesWithFilter({
  searchOptions = [],
  title,
  subcategories,
  articlesToIgnore,
  initialArticles,
  initialTotal,
}: ArticlesWithFilterProps) {
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<SubCategorySchema>()

  const options = useMemo(
    () =>
      uniqBy(
        searchOptions.map((item) => item.subcategory),
        getSlug
      ),
    [searchOptions]
  )

  const selectedArticleIds = useMemo(
    () =>
      selectedSubcategory &&
      uniqBy(
        searchOptions
          .filter(
            (sub) =>
              sub.subcategory.fields.slug === selectedSubcategory.fields.slug
          )
          .map((item) => item.articleIds)
          .flat(),
        identity
      ),
    [searchOptions, selectedSubcategory]
  )

  return (
    <Articles
      initialArticles={initialArticles}
      initialTotal={initialTotal}
      subcategories={subcategories}
      articleIds={selectedArticleIds}
      articlesToIgnore={articlesToIgnore}
    >
      <>
        <h2 className="heading-lg-regular pb-7">
          {title || 'Related articles'}
        </h2>
        <SubcategoriesFilter
          subcategories={options}
          currentSubcategory={selectedSubcategory}
          setCurrentSubcategory={setSelectedSubcategory}
        />
      </>
    </Articles>
  )
}

ArticlesWithFilter.displayName = 'ArticlesWithFilter'

export function Articles({
  subcategories,
  articlesToIgnore,
  articleIds,
  initialArticles = [],
  initialTotal = 0,
  children,
}: ArticlesProps) {
  const [articles, setArticles] = useState<ArticleSchema[]>(initialArticles)
  const [mounted, setMounted] = useState(false)
  const [total, setTotal] = useState<number>(initialTotal)
  const [page, setPage] = useState(0)
  const [articleIndexToFocus, setArticleIndexToFocus] = useState(-1)
  const [loading, setLoading] = useState<boolean>(false)

  const load = useCallback(
    async function ({ skip = 0, limit = PAGE_SIZE } = {}) {
      try {
        setLoading(true)
        return await getArticles({
          skip,
          limit,
          subcategories,
          articleIds,
          fetchedSlugs: articlesToIgnore?.map((article) => article.fields.slug),
        })
      } finally {
        setLoading(false)
      }
    },
    [subcategories, articleIds, articlesToIgnore]
  )

  const onLoadMore = async () => {
    const loaded = await load({
      skip: PAGE_SIZE * (page + 1),
      limit: PAGE_SIZE,
    })
    setPage(page + 1)
    setArticleIndexToFocus(articles.length)
    setArticles([...articles, ...loaded.articles])
  }

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      return
    }
    ;(async () => {
      const { articles, total } = await load()
      setArticles(articles)
      setArticleIndexToFocus(-1)
      setTotal(total)
      setPage(0)
    })()
  }, [load]) //Do not include mounted, should fire only when new load function created

  return (
    <div className={'p-5 md:px-8 md:py-7 lg:px-11 lg:py-8'}>
      {children}
      <div
        className={
          'grid auto-rows-fr grid-cols-1 justify-items-center gap-y-6 sm:gap-x-4 sm:gap-y-7 md:grid-cols-2 xl:grid-cols-[repeat(3,400px)] xl:gap-x-8'
        }
      >
        {articles.map((article, i) => (
          <ArticleCard
            article={article}
            key={article.fields.slug}
            focusOnMount={articleIndexToFocus === i}
          />
        ))}
      </div>
      {articles.length >= total && !loading ? null : (
        <Button
          className="mt-7 w-full lg:mt-9"
          variant={'primary-outline'}
          data-testid="load-more-button"
          disabled={loading}
          onClick={onLoadMore}
        >
          {loading ? 'Loading...' : 'Load more'}
        </Button>
      )}
    </div>
  )
}

Articles.displayName = 'Articles'
