import { useCallback, useRef, useState, type KeyboardEvent } from 'react'
import Image from 'next/image'
import { Command as CommandPrimitive } from 'cmdk'

import { Button } from '@/components/ui/button'

import {
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './ui/command'

export type Option = Record<'value' | 'label', string> & Record<string, string>

type AutoCompleteProps = {
  options: Option[]
  emptyMessage: string
  value?: Option
  onValueChange?: (value: Option) => void
  onClear?: () => void
  isLoading?: boolean
  disabled?: boolean
  placeholder?: string
  id?: string
}

export const AutoComplete = ({
  options,
  placeholder,
  emptyMessage,
  value,
  onValueChange,
  onClear,
  disabled,
  isLoading = false,
}: AutoCompleteProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selected, setSelected] = useState<Option>(value as Option)
  const [inputValue, setInputValue] = useState(value?.label || '')

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (!inputRef.current) return
      if (!isOpen) setOpen(true)
      if (event.key === 'Escape') setOpen(false)
    },
    [isOpen]
  )

  const handleClear = () => {
    setInputValue('')
    setSelected({ label: '', value: '' })
    onClear?.()
    inputRef.current?.focus()
  }

  const handleBlur = useCallback(() => {
    setOpen(false)
  }, [])

  const handleSelectOption = useCallback(
    (selectedOption: Option) => {
      setInputValue(selectedOption.label)
      setSelected(selectedOption)
      onValueChange?.(selectedOption)
      inputRef.current?.focus()
      setOpen(false)
    },
    [onValueChange]
  )

  return (
    <CommandPrimitive
      tabIndex={-1}
      defaultValue="-"
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      className="w-auto rounded-md border border-hudson-navy-800 focus-within:shadow-ring focus-within:outline-none focus-within:ring-2 focus-within:ring-accessibility-ring sm:w-[300px]"
    >
      <div className="flex justify-between pl-2 pr-1 sm:pl-3 sm:pr-2">
        <CommandInput
          value={inputValue}
          ref={inputRef}
          onValueChange={isLoading ? undefined : setInputValue}
          onFocus={() => setOpen(true)}
          placeholder={placeholder}
          disabled={disabled}
          role="combobox"
          aria-label="Filter by topic"
          aria-autocomplete="list"
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          aria-controls="combo-listbox"
        />
        {inputValue && (
          <Button
            className="size-4 self-center !p-0 lg:size-[18px]"
            size="circle-xs"
            ref={buttonRef}
            variant="primary"
            onClick={handleClear}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                handleClear()
              }
            }}
            data-testid="clear-search-button"
            aria-label="Clear search"
          >
            <Image
              className="size-2"
              src="/assets/svg/close.svg"
              alt="Close Logo"
              height={14}
              width={14}
            />
          </Button>
        )}
      </div>
      <div className="relative">
        <CommandList role="listbox" id="combo-listbox">
          {isOpen && (
            <div className="absolute top-1 z-10 w-full rounded-md bg-white drop-shadow-lg animate-in fade-in-0 zoom-in-95">
              {options && options.length > 0 && !isLoading && (
                <CommandGroup className="rounded-md border border-hudson-navy-800">
                  {options.map((option, index) => (
                    <CommandItem
                      key={option.value}
                      id={`option-${index}`}
                      value={option.label}
                      role="option"
                      onMouseDown={(e) => {
                        e.preventDefault()
                      }}
                      onSelect={() => handleSelectOption(option)}
                    >
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              )}
              {!isLoading && (
                <CommandPrimitive.Empty className="select-none rounded-sm px-2 py-3 text-center text-sm">
                  {emptyMessage}
                </CommandPrimitive.Empty>
              )}
            </div>
          )}
        </CommandList>
      </div>
    </CommandPrimitive>
  )
}
